import { graphql } from 'gatsby'
import React from 'react'
import { Container, Heading, Section, Wysiwyg } from 'src/components/Base'
import NewsGrid from 'src/components/NewsGrid'
import Seo from 'src/components/Seo'
import { Hero } from 'src/sections'

const TemplateBlogOverview = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspBlogOverview.blogOverviewIntro

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
        </Container>
      </Section>
      <NewsGrid
        postsPerPage={9}
        posts={data.allWpNeuigkeit.nodes}
        hasBackground={false}
        newsLocale={page.language.locale}
      />
    </>
  )
}

export const pageQuery = graphql`
  query BlogOverviewById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspBlogOverview {
        blogOverviewIntro {
          heading
          wysiwyg
        }
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
    allWpNeuigkeit(sort: { order: DESC, fields: atspNews___newsDate }) {
      nodes {
        atspLanguage {
          doNotUseAsDefault
        }
        atspNews {
          newsDate
          newsImageTeaser {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        title
        link
        slug
        excerpt
        language {
          locale
        }
      }
    }
  }
`
export default TemplateBlogOverview
